<template>
  <el-upload
    class="uploadBox"
    :style="'width: ' + width + '; height: ' + height"
    :action="actionUrl"
    :show-file-list="false"
    :on-success="handleAvatarSuccess"
    :before-upload="beforeAvatarUpload"
    v-loading="loading"
  >
    <div v-if="imageUrl" class="uploadBox-img-div">
      <img :src="imageUrl" />
    </div>
    <div v-else class="uploadBox-plus-div">
      <i class="el-icon-plus"></i>
    </div>
  </el-upload>
</template>

<script>
/**
 * 主要使用说明
 * props (width|height)
 * methods (getValue|setValue)
 */
import { config } from "@/config/index.js";
export default {
  props: {
    width: {
      type: String,
      default: "100px",
    },
    height: {
      type: String,
      default: "100px",
    },
  },
  data() {
    return {
      loading: false,
      // imageUrl: "http://jianzhan.dev.xinzhidi.cn/upload/20211011/300c745cf6c267cf633b09d368ec3052.png",
      imageUrl: "",

      actionUrl: config.web_host + "/index.php/api/common/upload/do_upload",
    };
  },
  methods: {
    setValue(p) {
      this.imageUrl = p;
    },
    getValue() {
      return this.imageUrl;
    },

    beforeAvatarUpload(file) {
      this.loading = true;

      //   const isJPG = file.type === "image/jpeg";
      const isLt = file.size / 1024 / 1024 < 4;

      //   if (!isJPG) {
      //     this.$message.error("上传头像图片只能是 JPG 格式!");
      //   }
      if (!isLt) {
        this.$message.error("上传头像图片大小不能超过 4MB!");
      }

      let res = isLt;

      if (!res) {
        this.loading = false;
      }

      return res;
    },

    handleAvatarSuccess(res, file) {
      //   console.log(res);
      //   console.log(file);
      this.loading = false;
      if (res.code == 200) {
        this.imageUrl = res.data.file_url;
      } else if (res.code == 201) {
        this.$notify.error({
          title: "提示",
          message: res.msg,
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.uploadBox {
  width: 100px;
  height: 100px;
  position: relative;

  display: inline-block;

  border: 1px dashed orange;
  border-radius: 10px;
  overflow: hidden;

  .uploadBox-img-div {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .uploadBox-plus-div {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
  }
}
</style>