
import request from '@/utils/request'

export const get_quan_info_and_web_day = (params) => {
    let url = "/index.php/api/v1_0_0.quan/get_quan_info_and_web_day";
    return request.post(url, params)
}


export const use_quan = (params) => {
    let url = "/index.php/api/v1_0_0.quan/use_quan";
    return request.post(url, params)
}
