<template>
  <!-- 访客统计 -->
  <div class="echartsBox">
    <div ref="echartsDiv" style="width: 100%; height: 355px"></div>

    <div class="btn-box">
      <el-button
        :class="time_type == 0 ? 'choosedBtn' : ''"
        @click="getCount(0)"
      >
        {{ $lang("userV2_right_right_userCenter_btns1") }}
      </el-button>
      <div></div>
      <el-button
        :class="time_type == 1 ? 'choosedBtn' : ''"
        @click="getCount(1)"
      >
        {{ $lang("userV2_right_right_userCenter_btns2") }}
      </el-button>
      <div></div>
      <el-button
        :class="time_type == 7 ? 'choosedBtn' : ''"
        @click="getCount(7)"
      >
        {{ $lang("userV2_right_right_userCenter_btns3") }}
      </el-button>
      <div></div>
      <el-button
        :class="time_type == 30 ? 'choosedBtn' : ''"
        @click="getCount(30)"
      >
        {{ $lang("userV2_right_right_userCenter_btns4") }}
      </el-button>
    </div>

    <div class="line"></div>
    <div class="echarts_title">
      {{ $lang("userV2_right_right_userCenter_echarts_title") }}
    </div>
  </div>
</template>

<script>
import { get_count } from "@/api/viewcount";

import * as echarts from "echarts";

let option = {
  //标题
  title: {
    text: "",
    left: 28,
    top: 15,
    textStyle: {
      //文字颜色
      color: "#333",
      //字体风格
      fontStyle: "normal",
      //字体粗细
      fontWeight: "700",
      //字体系列
      fontFamily: "PingFangSC-Medium, PingFang SC",
      //字体大小
      fontSize: 14,
    },
  },
  //提示框
  tooltip: {
    trigger: "axis",
  },
  //图例
  legend: {
    data: ["访客统计"],
    top: 15,
  },
  //直角坐标系
  grid: {
    left: "3%",
    right: "4%",
    bottom: "3%",
    containLabel: true,
  },
  //x轴
  xAxis: {
    type: "category",
    boundaryGap: false,
  },
  //y轴
  yAxis: {
    type: "value",
    max: function (value) {
      return Math.ceil(value.max * 1.2);
    },
  },
  //核心数据
  series: [
    {
      data: [
        ["1时", 1],
        ["2时", 3],
        ["3时", 4],
        ["4时", 2],
        ["5时", 11],
        ["6时", 12],
        ["7时", 16],
        ["8时", 3],
        ["9时", 9],
        ["10时", 11],
        ["11时", 12],
        ["12时", 18],
        ["13时", 10],
        ["14时", 10],
        ["15时", 21],
        ["16时", 11],
        ["17时", 21],
        ["18时", 31],
        ["19时", 11],
        ["20时", 1],
        ["21时", 1],
        ["22时", 1],
        ["23时", 1],
        ["24时", 1],
      ],
      type: "line",
      // symbol: "none",
      showSymbol: false,
      areaStyle: {
        normal: {
          //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "#56B660", // 0% 处的颜色
            },
            {
              offset: 1,
              color: "#F7FBF7", // 100% 处的颜色
            },
          ]), //背景渐变色
        },
      },
      itemStyle: {
        normal: {
          color: "#56B660", //改变折线点的颜色
          lineStyle: {
            color: "#56B660", //改变折线颜色
          },
        },
      },
    },
  ],
};
export default {
  data() {
    return {
      time_type: 0,
      web_type: "",
      show_type: "",
      myChart: null,
    };
  },
  mounted() {
    this.myChart = echarts.init(this.$refs.echartsDiv);
  },

  methods: {
    start(web_type, show_type) {
      this.web_type = web_type;
      this.show_type = show_type;
      this.getCount(0);
    },
    getCount(time_type) {
      this.time_type = time_type;

      let p = {
        time_type: this.time_type,
        web_type: this.web_type,
        show_type: this.show_type,
      };

      this.myChart.showLoading({
        text: "加载中",
        color: "#56b660",
        textColor: "#000",
        maskColor: "rgba(255, 255, 255, 0.8)",
        zlevel: 0,

        // 字体大小。从 `v4.8.0` 开始支持。
        fontSize: 12,
        // 是否显示旋转动画（spinner）。从 `v4.8.0` 开始支持。
        showSpinner: true,
        // 旋转动画（spinner）的半径。从 `v4.8.0` 开始支持。
        spinnerRadius: 10,
        // 旋转动画（spinner）的线宽。从 `v4.8.0` 开始支持。
        lineWidth: 5,
        // 字体粗细。从 `v5.0.1` 开始支持。
        fontWeight: "normal",
        // 字体风格。从 `v5.0.1` 开始支持。
        fontStyle: "normal",
        // 字体系列。从 `v5.0.1` 开始支持。
        fontFamily: "sans-serif",
      });

      get_count(p)
        .then((res) => {
          let list = res.data.list;
          option.series[0].data = list;
          this.myChart.setOption(option);
        })
        .catch((err) => {})
        .finally(() => {
          this.myChart.hideLoading();
        });
    },
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
  },
};
</script>

<style lang="less" scoped>
// echarts
.echartsBox {
  position: relative;
  background-color: #fff;
  .btn-box {
    position: absolute;
    right: 4px;
    top: 6px;
    display: flex;
    align-items: center;
    > div {
      width: 1px;
      height: 10px;
      background: #d8d8d8;
      border-radius: 2px;
    }

    .el-button {
      border: 0;
      background-color: #fff;
    }
    .el-button {
      color: #606266;
    }
    .el-button:hover {
      color: #56b660;
    }
    .choosedBtn {
      color: #56b660;
    }
  }
  .line {
    position: absolute;
    left: 20px;
    top: 16px;
    width: 2px;
    height: 20px;
    background: #56b660;
    border-radius: 2px;
  }
  .echarts_title {
    position: absolute;
    left: 30px;
    top: 16px;
  }
}
</style>