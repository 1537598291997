import { render, staticRenderFns } from "./dialog_web_data.vue?vue&type=template&id=5568c78c&scoped=true&"
import script from "./dialog_web_data.vue?vue&type=script&lang=js&"
export * from "./dialog_web_data.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5568c78c",
  null
  
)

export default component.exports