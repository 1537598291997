/*
viewcount 相关接口
*/

import request from '@/utils/request'


export const get_view_count = (params) => {
    let url = "/index.php/api/v1_0_0.viewcount/get_count";
    return request.post(url, params)
}

export const get_count = (params) => {
    let url = "/index.php/api/v1_0_0.viewcount/get_count_list";
    return request.post(url, params)
}


