<template>
  <el-dialog
    title="绑定外部域名教程"
    :visible.sync="dialogVisible"
    width="400px"
    append-to-body
  >
    <div>
        <p>以</p>
        <p>外部域名 www.example.com </p>
        <p>内部域名 xxxx.dev.xinzhidi.cn </p>
        <p>举例 如下：</p>
    </div>
    <br>
    <div style="height:150px">
      <el-steps direction="vertical">
        <el-step
          title="做cname解析指向"
          description="外部域名www.example.com 做cname解析指向 内部域名xxxx.dev.xinzhidi.cn"
        ></el-step>
        <el-step
          title="绑定外部域名"
          description="在设置中 绑定外部域名为 www.example.com"
        ></el-step>
      </el-steps>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="dialogVisible = false">明白了</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    start() {
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
</style>