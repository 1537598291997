<template>
  <el-dialog
    :title="$lang('dialogEditWeb_title')"
    :visible.sync="dialogVisible"
    width="700px"
  >
    <div v-loading="loading_get">
      <jiaoCheng ref="jiaoCheng"></jiaoCheng>

      <el-form ref="form" label-width="160px">
        <el-form-item :label="$lang('dialogEditWeb_subtitle1')">
          <el-input v-model="web_name"></el-input>
        </el-form-item>
        <el-form-item :label="$lang('dialogEditWeb_nav_icon')">
          <uploadImg ref="uploadImg"></uploadImg>
        </el-form-item>

        <el-form-item :label="$lang('dialogEditWeb_subtitle2')">
          <el-input v-model="web_beian"></el-input>
        </el-form-item>

        <el-form-item :label="$lang('dialogEditWeb_subtitle3')">
          <el-col :span="15">
            <el-input v-model="web_host_out"></el-input>
          </el-col>
          <el-col :span="9">
            &nbsp; &nbsp; &nbsp;
            <el-button @click="seeJiaoCheng" type="text">
              {{ $lang("dialogEditWeb_btn1") }}
            </el-button>
          </el-col>
        </el-form-item>

        <el-form-item :label="$lang('dialogEditWeb_subtitle4')">
          <el-switch v-model="web_status"></el-switch>
        </el-form-item>

        <el-form-item :label="$lang('dialogEditWeb_subtitle5')">
          <el-input type="textarea" v-model="web_seo_str" rows="4"></el-input>
        </el-form-item>
      </el-form>

      <div style="padding: 20px; text-align: right">
        <el-button @click="dialogVisible = false">
          {{ $lang("dialogEditWeb_btn2") }}
        </el-button>
        <el-button type="primary" @click="setBaseInfo" :loading="loading_set">
          {{ $lang("dialogEditWeb_btn3") }}
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import uploadImg from "@/components/com/uploadImg/index.vue";
import { get_base_info, set_base_info } from "@/api/web.js";

import jiaoCheng from "./dialog_jiaocheng.vue";
export default {
  components: { jiaoCheng,uploadImg },
  data() {
    return {
      loading_get: false,
      loading_set: false,
      dialogVisible: false,

      web_id: "",
      web_name: "",
      web_beian: "",
      web_host_out: "",
      web_status: false,
      web_seo_str: "",
    };
  },
  methods: {
    start(web) {
      this.web_id = web.id;
      this.dialogVisible = true;
      this.getBaseInfo();

    },

    getBaseInfo() {
      let p = {
        web_id: this.web_id,
      };
      this.loading_get = true;

      get_base_info(p)
        .then((res) => {
          if (res.code == 200) {
            this.web_name = res.data.web.name;
            this.web_host_out = res.data.web.host_out;
            this.web_beian = res.data.web.beian;
            this.$refs.uploadImg.setValue(res.data.web.favicon);
            this.web_status = res.data.web.status == 1;
            this.web_seo_str = res.data.web.seo_str;
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.loading_get = false;
        });
    },

    setBaseInfo() {
      let p = {
        web_id: this.web_id,
        name: this.web_name,
        host_out: this.web_host_out,
        beian: this.web_beian,
        favicon: this.$refs.uploadImg.getValue(),

        status: this.web_status ? 1 : 2,
        seo_str: this.web_seo_str,
      };
      this.loading_set = true;

      set_base_info(p)
        .then((res) => {
          if (res.code == 200) {
            this.dialogVisible = false;
            this.$emit("setBaseInfoCallback");
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.loading_set = false;
        });
    },

    seeJiaoCheng() {
      this.$refs.jiaoCheng.start();
    },
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
  },
};
</script>

<style lang="less" scoped>
</style>