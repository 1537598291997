/*
网站实例 相关接口
*/

import request from '@/utils/request'

export const get_my_web_list = (params) => {
    let url = "/index.php/api/v1_0_0.web/get_my_web_list";
    return request.post(url, params)
}

export const get_web_info = (params) => {
    let url = "/index.php/api/v1_0_0.web/get_web_info";
    return request.post(url, params)
}

export const update_web_info = (params) => {
    let url = "/index.php/api/v1_0_0.web/update_web_info";
    return request.post(url, params)
}

export const del_web = (params) => {
    let url = "/index.php/api/v1_0_0.web/del_web";
    return request.post(url, params)
}
export const set_host_out = (params) => {
    let url = "/index.php/api/v1_0_0.web/set_host_out";
    return request.post(url, params)
}


export const get_base_info = (params) => {
    let url = "/index.php/api/v1_0_0.web/get_base_info";
    return request.post(url, params)
}
export const set_base_info = (params) => {
    let url = "/index.php/api/v1_0_0.web/set_base_info";
    return request.post(url, params)
}

