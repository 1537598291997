<template>
  <el-dialog
    :title="$lang('dialog_web_data_title')"
    :visible.sync="dialogVisible"
    width="1000px"
  >
    <everyWebData ref="everyWebData" />

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">
        {{ $lang("dialog_web_data_btn1") }}
      </el-button>
      <el-button type="primary" @click="dialogVisible = false">
        {{ $lang("dialog_web_data_btn2") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import everyWebData from "@/views/index/userCenter/index/c/c/everyWebData.vue";

export default {
  components: { everyWebData },
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    start(web_id) {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.everyWebData.start(web_id);
      });
    },
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
  },
};
</script>

<style lang="less" scoped>
</style>