<template>
  <div>
    <dialogQr ref="dialogQr" @closeCallback="closeCallback" />
    <el-dialog
      :title="$lang('dialogxufei_title')"
      :visible.sync="dialogVisible"
      width="400px"
    >
      <div>
        <el-input
          v-model="day"
          type="number"
          :placeholder="$lang('dialogxufei_title')"
        ></el-input>
      </div>

      <br />
      <div>
        <el-tag @click="day = 7" class="tianBtn">
          {{ $lang("dialogxufei_date1") }}
        </el-tag>
        <el-tag @click="day = 30" class="tianBtn" type="success">{{
          $lang("dialogxufei_date2")
        }}</el-tag>
        <el-tag @click="day = 90" class="tianBtn" type="info">{{
          $lang("dialogxufei_date3")
        }}</el-tag>
        <el-tag @click="day = 180" class="tianBtn" type="warning">{{
          $lang("dialogxufei_date4")
        }}</el-tag>
        <el-tag @click="day = 365" class="tianBtn" type="danger">{{
          $lang("dialogxufei_date5")
        }}</el-tag>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">
          {{ $lang("dialogxufei_btn1") }}
        </el-button>
        <el-button type="primary" @click="createOrder" :loading="loading">
          {{ $lang("dialogxufei_btn2") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { web_renew } from "@/api/orderSubmit.js";
import { wx_unifiedorder } from "@/api/paywx.js";
import { get_info as get_order_info } from "@/api/order.js";

import dialogQr from "@/components/common/dialogQr.vue";

export default {
  components: { dialogQr },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      day: 0,
      web_id: 0,
      timer: null,
      order_num: "",
    };
  },
  methods: {
    start(web) {
      this.web_id = web.id;
      this.day = 365;
      this.dialogVisible = true;
    },
    createOrder() {
      let p = {
        web_id: this.web_id,
        day: this.day,
      };

      this.loading = true;
      web_renew(p)
        .then((res) => {
          if (res.code == 200) {
            console.log(res);
            let order_num = res.data.order_num;
            this.startWxPay(order_num);
          }
        })
        .catch((err) => {})
        .finally(() => {});
    },
    startWxPay(order_num) {
      let p = {
        order_num,
      };
      this.loading = true;
      wx_unifiedorder(p)
        .then((res) => {
          if (res.code == 200) {
            console.log(res);
            this.dialogVisible = false;
            let code_url = res.data.code_url;
            let fee_f = res.data.fee_f;
            this.$refs.dialogQr.start(code_url, "微信支付(" + fee_f + ")");

            this.startTimer(order_num);
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.loading = false;
        });
    },

    startTimer(order_num) {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setInterval(() => {
        get_order_info({ order_num })
          .then((res) => {
            if (res.code == 200 && res.data.order.status == 2) {
              this.endTimer();
              this.$emit("payOver");
            }
          })
          .catch((err) => {
            this.endTimer();
          })
          .finally(() => {});
      }, 3000);
    },
    endTimer() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = null;
      this.$refs.dialogQr.dialogVisible = false;
    },
    closeCallback() {
      clearInterval(this.timer);
    },
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
  },
};
</script>

<style lang="less" scoped>
.tianBtn {
  cursor: pointer;
}
</style>