<template>
  <el-dialog title="绑定外部域名" :visible.sync="dialogVisible" width="600px">
    <div>
      <el-button @click="lookHow" type="text">查看教学</el-button>
    </div>
    <div>
      <el-input
        v-model="host_out"
        placeholder="请输入要绑定的外部域名"
      ></el-input>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="onSubmit" :loading="loading"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { set_host_out } from "@/api/web.js";

export default {
  data() {
    return {
      dialogVisible: false,
      loading: false,
      web_id: "",
      host_out: "",
    };
  },
  methods: {
    start(web) {
      this.web_id = web.id;
      this.host_out = web.host_out;
      this.dialogVisible = true;
    },

    lookHow() {
      this.$notify({
        type: "error",
        title: "暂无教学",
      });
    },
    onSubmit() {
      let p = {
        web_id: this.web_id,
        host_out: this.host_out,
      };

      this.loading = true;
      set_host_out(p)
        .then((res) => {
          if (res.code == 200) {
            this.dialogVisible = false;
            this.$emit("setHostOutSuccess");
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
</style>