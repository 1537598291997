<template>
  <el-dialog title="使用优惠券" :visible.sync="dialogVisible" width="500px">
    <div v-loading="loading">
      <el-form label-width="180px" v-if="step == 1">
        <el-form-item label="优惠券号码">
          <el-input v-model="num"></el-input>
        </el-form-item>
        <el-form-item label="优惠券密码">
          <el-input v-model="password"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button @click="getQuanInfo">下一步</el-button>
        </el-form-item>
      </el-form>

      <el-form label-width="200px" v-if="step == 2">
        <el-form-item label="优惠券面值">
          {{ quan.fee / 100 }} 元
        </el-form-item>
        <el-form-item label="可续费天数">
          {{ day }} 天
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="useQuan">确定续费</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import { get_quan_info_and_web_day, use_quan } from "@/api/quan.js";
export default {
  data() {
    return {
      dialogVisible: false,
      num: "",
      password: "",
      step: 1,
      web: null,
      loading: false,

      quan: {},
      day: 0,
    };
  },
  methods: {
    start(web) {
      console.log("web", web);

      this.web = web;
      this.step = 1;
      this.num = '';
      this.password = '';
      this.dialogVisible = true;
    },

    getQuanInfo() {
      let p = {
        num: this.num,
        password: this.password,
        web_id: this.web.id,
      };
      this.loading = true;
      get_quan_info_and_web_day(p)
        .then((res) => {
          if (res.code == 200) {
            this.step = 2;
            this.quan = res.data.quan;
            this.day = res.data.day;
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.loading = false;
        });
    },

    useQuan() {
      let p = {
        num: this.num,
        password: this.password,
        web_id: this.web.id,
      };
      this.loading = true;
      use_quan(p)
        .then((res) => {
          if (res.code == 200) {
            this.dialogVisible = false;
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.$emit("callback");

          this.loading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
</style>