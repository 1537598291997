<template>
  <el-dialog
    :title="$lang('dialog_form_data_title')"
    :visible.sync="dialogVisible"
    width="600px"
  >
    <div v-loading="loading">
      <el-table height="400px" :data="list" border style="width: 100%">
        <el-table-column
          prop="id"
          :label="$lang('dialog_form_data_subtitle1')"
          width="100"
          align="center"
        />
        <el-table-column
          prop="create_time_f"
          :label="$lang('dialog_form_data_subtitle2')"
          width="200"
          align="center"
        />

        <el-table-column
          :label="$lang('dialog_form_data_subtitle3')"
          align="center"
        >
          <template slot-scope="scope">
            <!-- {{ scope.row.form_content }} -->

            <div
              v-for="(item, i) in JSON.parse(scope.row.form_content)"
              :key="i"
            >
              {{ i }}:{{ item }}
            </div>
          </template>
        </el-table-column>
      </el-table>

      <div style="margin: 10px 0; text-align: right">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="page"
          :page-size="page_size"
          @current-change="currentChange"
          :total="count"
        ></el-pagination>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">
        {{ $lang("dialog_form_data_btn1") }}
      </el-button>
      <el-button type="primary" @click="dialogVisible = false">
        {{ $lang("dialog_form_data_btn2") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { get_list } from "@/api/contactus.js";
export default {
  data() {
    return {
      dialogVisible: false,
      loading: false,

      web_id: 0,
      list: [],
      page: 1,
      page_size: 10,
      count: 0,
    };
  },
  methods: {
    start(web_id) {
      this.web_id = web_id;
      this.dialogVisible = true;
      this.getList();
    },

    currentChange(page) {
      this.page = page;
      this.getList();
    },
    getList() {
      let p = {
        web_id: this.web_id,
        page: this.page,
        page_size: this.page_size,
      };
      this.loading = true;

      get_list(p)
        .then((res) => {
          if (res.code == 200) {
            this.list = res.data.list;
            this.count = res.data.count;
          }
        })
        .catch()
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
  },
};
</script>

<style lang="less" scoped>
</style>