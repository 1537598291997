<template>
  <div class="webDataBox">
    <div class="left">
      <div class="card top1" v-loading="loading1">
        <p class="card-num">{{ count1 }}</p>
        <p class="card-title">
          {{ $lang("everyWebData_title1") }}
        </p>
      </div>

      <p class="line"></p>

      <div class="card top2" v-loading="loading2">
        <p class="card-num">{{ count2 }}</p>
        <p class="card-title">
          {{ $lang("everyWebData_title2") }}
        </p>
      </div>
    </div>
    <div class="right">
      <echartsBox ref="echartsBox" />
    </div>
  </div>
</template>

<script>
import echartsBox from "./echartsBox.vue";
import { get_view_count } from "@/api/viewcount";

export default {
  components: {
    echartsBox,
  },
  data() {
    return {
      loading1: false,
      loading2: false,
      count1: "",
      count2: "",
    };
  },
  methods: {
    start(web_id) {
      this.get_count1(web_id);
      this.get_count2(web_id);
      this.$refs.echartsBox.start(web_id, "number");
    },
    get_count1(web_id) {
      let p = {
        time_type: "today",
        web_type: web_id,
        show_type: "number",
      };
      this.loading1 = true;

      get_view_count(p)
        .then((res) => {
          this.count1 = res.data.count;
        })
        .catch((err) => {})
        .finally(() => {
          this.loading1 = false;
        });
    },
    get_count2(web_id) {
      let p = {
        time_type: "all",
        web_type: web_id,
        show_type: "number",
      };
      this.loading2 = true;

      get_view_count(p)
        .then((res) => {
          this.count2 = res.data.count;
        })
        .catch((err) => {})
        .finally(() => {
          this.loading2 = false;
        });
    },
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
  },
};
</script>

<style lang="less" scoped>
.webDataBox {
  height: 457px;
  display: flex;
  align-items: center;
  // 左侧
  .left {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 200px;
    .top1 {
      margin-bottom: 8px;
      color: #299ffe;
    }
    .line {
      width: 157px;
      height: 1px;
      border: 1px solid #eaeaea;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .top2 {
      color: #57b661;
    }
    .card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 164px;
      background-color: #fff;
      .card-title {
        font-size: 14px;
        color: #b2bfc2;
      }
      .card-num {
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        margin-bottom: 20px;
        font-size: 24px;
      }
    }
  }

  // 右侧
  .right {
    flex: 1;
    height: 355px;
  }
}
</style>