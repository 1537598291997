<template>
  <myCenterLayout
    select="web"
    :pageTitle="languagePackage[language].userV2_right_right_myWebsite_title"
  >
    <webList></webList>
    
  </myCenterLayout>
</template>

<script>
import myCenterLayout from "@/components/layout/myCenter/index.vue";
import webList from "./c/webList.vue";
import languagePackage from "@/languagePackage/index.js";

export default {
  components: {
    myCenterLayout,
    webList,
  },
  data() {
    return {
      languagePackage,
    };
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
  },
};
</script>

<style lang="less" scoped>
</style>