<template>
  <div class="web-bodyMain" :class="'skin_theme_' + skin_theme">
    <dialogWebData ref="dialogWebData" />
    <dialogFormdata ref="dialogFormdata" />

    <dialogxufei ref="dialogxufei" @payOver="payOver" />

    <dialogsethostout ref="dialogsethostout" @setHostOutSuccess="getList()" />

    <dialogEditWeb ref="dialogEditWeb" @setBaseInfoCallback="getList()" />

    <dialogQuan ref="dialogQuan" @callback="getList()" />

    <div class="my-web">
      <div class="list-box">
        <!-- 订单搜索 -->
        <div class="web-search">
          <div>
            <el-input
              v-model="name"
              :placeholder="
                languagePackage[language].userV2_right_right_My_website_search1
              "
            />

            <span style="margin-left: 20px">
              {{
                languagePackage[language].userV2_right_right_My_website_search2
              }}
            </span>

            <el-input
              style="width: 94px"
              v-model="long"
              :placeholder="
                languagePackage[language].userV2_right_right_My_website_search3
              "
            />
            {{
              languagePackage[language].userV2_right_right_My_website_search4
            }}
          </div>

          <div class="search-btn">
            <!-- <el-button class="reset">
          <div class="btn-info">
            <span class="el-icon-refresh-right icon"></span>
            <span>重置</span>
          </div></el-button
        > -->
            <el-button type="primary" @click="onSearch">
              <div class="btn-info">
                <span class="el-icon-search icon"></span>
                <span>
                  {{
                    languagePackage[language]
                      .userV2_right_right_My_website_search_btn
                  }}
                </span>
              </div>
            </el-button>
          </div>
        </div>

        <!-- 订单列表 -->
        <div class="web-list">
          <el-table
            :height="tableHeight"
            v-loading="loading"
            :data="list"
            border
            style="width: 100%"
          >
            <el-table-column
              prop="name"
              :label="
                languagePackage[language]
                  .userV2_right_right_My_website_search_list_title1
              "
              width="150"
              align="center"
            />

            <el-table-column
              :label="
                languagePackage[language]
                  .userV2_right_right_My_website_search_list_title2
              "
              width="160"
              align="center"
            >
              <template slot-scope="scope">
                <span style="color: green" v-if="!scope.row.is_end">{{
                  scope.row.end_time_f
                }}</span>
                <span style="color: red" v-if="scope.row.is_end">{{
                  scope.row.end_time_f
                }}</span>
              </template>
            </el-table-column>

            <el-table-column
              :label="
                languagePackage[language]
                  .userV2_right_right_My_website_search_list_title3
              "
              width="200"
              align="center"
            >
              <template slot-scope="scope">
                <div>
                  <a style="color:#409EFF" :href="'http://' + scope.row.host_in" target="_blank">{{
                    scope.row.host_in
                  }}</a>
                  ({{
                    languagePackage[language]
                      .userV2_right_right_My_website_search_list_tips1
                  }})
                </div>

                <div style="height:1px;background:#d7cccc73;margin:5px"></div>
                <div style="margin-top: 5px">
                  <a style="color:#409EFF" v-if="scope.row.host_out" :href="'http://' + scope.row.host_out" target="_blank"
                    >{{ scope.row.host_out }}
                  </a>

                  <span v-if="!scope.row.host_out">{{languagePackage[language].userV2_right_right_My_website_search_list_tips3}}</span>
                  ({{
                    languagePackage[language]
                      .userV2_right_right_My_website_search_list_tips2
                  }})
                </div>
              </template>
            </el-table-column>
            <el-table-column
              :label="
                languagePackage[language]
                  .userV2_right_right_My_website_search_list_title4
              "
              width=""
              header-align="center"
              align="center"
            >
              <template slot-scope="scope">
                <el-button @click="goEdit(scope.row)" type="text" size="small">
                  {{
                    languagePackage[language]
                      .userV2_right_right_My_website_search_list_btn1
                  }}
                </el-button>
                <!-- <el-button @click="editHostOut(scope.row)" type="text" size="small"
              >设置</el-button
            > -->

                <el-button @click="editWeb(scope.row)" type="text" size="small">
                  {{
                    languagePackage[language]
                      .userV2_right_right_My_website_search_list_btn2
                  }}
                </el-button>

                <el-button
                  @click="doDel(scope.row)"
                  type="text"
                  size="small"
                  class="delete"
                >
                  {{
                    languagePackage[language]
                      .userV2_right_right_My_website_search_list_btn3
                  }}
                </el-button>
                <el-button
                  @click="xufei(scope.row)"
                  type="text"
                  size="small"
                  class="data"
                >
                  {{
                    languagePackage[language]
                      .userV2_right_right_My_website_search_list_btn4
                  }}
                </el-button>
                <el-button
                  @click="seeData(scope.row)"
                  type="text"
                  size="small"
                  class="data"
                >
                  {{
                    languagePackage[language]
                      .userV2_right_right_My_website_search_list_btn5
                  }}
                </el-button>

                <el-button
                  @click="seeFormdata(scope.row)"
                  type="text"
                  size="small"
                  class="data"
                >
                  {{
                    languagePackage[language]
                      .userV2_right_right_My_website_search_list_btn6
                  }}
                </el-button>

                <el-button
                  @click="quanXufei(scope.row)"
                  type="text"
                  size="small"
                  class="data"
                >
                  {{
                    languagePackage[language]
                      .userV2_right_right_My_website_search_list_btn7
                  }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>

          <div style="margin: 10px 0; text-align: right">
            <el-pagination
              background
              layout="total, prev, pager, next"
              :current-page="page"
              :page-size="page_size"
              @current-change="currentChange"
              :total="count"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import dialogsethostout from "@/views/index/userCenter/web/c/c/dialog_setHostOut.vue";
import { get_my_web_list as get_list, del_web } from "@/api/web.js";

import dialogxufei from "@/views/index/userCenter/web/c/dialogxufei.vue";
import dialogWebData from "@/views/index/userCenter/web/c/c/dialog_web_data.vue";
import dialogFormdata from "@/views/index/userCenter/web/c/c/dialog_form_data.vue";
import dialogEditWeb from "@/views/index/userCenter/web/c/c/dialogEditWeb.vue";

import languagePackage from "@/languagePackage/index.js";

import dialogQuan from "@/views/v2/userCenter/web/c/c/dialogQuan.vue";

export default {
  components: {
    dialogxufei,
    dialogsethostout,
    dialogWebData,
    dialogFormdata,
    dialogEditWeb,
    dialogQuan,
  },
  data() {
    return {
      options: [
        {
          value: "web_renew",
          label: "网站续费",
        },
      ],
      languagePackage,
      curLanguage: "简体中文",

      name: "",
      long: "",
      page: 1,
      page_size: 10,

      list: [],
      count: 0,
      loading: false,

      tableHeight: document.body.offsetHeight - 71 - 62 - 150,
    };
  },
  mounted() {
    this.getList();
    this.curLanguage = this.$store.getters.language;
    // console.log(document.body.offsetHeight);
  },
  methods: {
    getList() {
      this.loading = true;

      let p = {
        name: this.name,
        long: this.long,
        page: this.page,
        page_size: this.page_size,
      };
      get_list(p)
        .then((res) => {
          this.list = res.data.list;
          this.count = res.data.count;
        })
        .catch((err) => {})
        .finally(() => {
          // 结束 loadingGet
          this.loading = false;
        });
    },

    onSearch() {
      this.count = 0;
      this.page = 1;
      this.page_size = 10;
      this.list = [];
      this.getList();
    },

    currentChange(page) {
      this.page = page;
      this.getList();
    },

    goEdit(web) {
      this.$router.push("/editWeb?web_id=" + web.id);
    },

    editHostOut(web) {
      this.$refs.dialogsethostout.start(web);
    },

    editWeb(web) {
      this.$refs.dialogEditWeb.start(web);
    },

    doDel(web) {
      this.changeLanguage(this.curLanguage, web);
    },

    changeLanguage(curLanguage, web) {
      if (curLanguage === "简体中文") {
        this.$nextTick(() => {
          this.$confirm("此操作将永久删除此网站, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.do_del(web.id);
            })
            .catch(() => {});
        });
      } else {
        this.$nextTick(() => {
          this.$confirm(
            "This operation will permanently delete this website. Do you want to continue?",
            "Tips",
            {
              confirmButtonText: "Confirm",
              cancelButtonText: "Cancel",
              type: "warning",
            }
          )
            .then(() => {
              this.do_del(web.id);
            })
            .catch(() => {});
        });
      }
    },

    do_del(web_id) {
      this.loading = true;
      del_web({ web_id })
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getList();
          }
        })
        .catch()
        .finally(() => {
          this.loading = false;
        });
    },

    xufei(web) {
      this.$refs.dialogxufei.start(web);
    },

    quanXufei(web) {
      this.$refs.dialogQuan.start(web);
    },

    payOver() {
      this.$notify({
        type: "success",
        title: "支付成功",
      });

      this.getList();
    },

    seeData(web) {
      this.$refs.dialogWebData.start(web.id);
    },
    seeFormdata(web) {
      this.$refs.dialogFormdata.start(web.id);
    },
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
    skin_theme() {
      return this.$store.getters.skin_theme;
    },
  },
  watch: {
    "$store.state.language"(newval, olval) {
      this.curLanguage = newval;
    },
  },
};
</script>

<style lang="less" scoped>
.web-bodyMain {
  padding: 1px;
  border-radius: 14px;
  margin: 0 25px;
  .my-web {
    // width: 100%;
    // height: 100%;

    overflow: hidden;

    box-sizing: border-box;
    border-radius: 14px;

    .web-search,
    .web-list {
      box-sizing: border-box;
    }

    .web-search {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      padding: 25px 20px 20px 20px;
      .el-input {
        width: 190px;
        /deep/.el-input__inner {
          padding-right: 0 !important;
          height: 26px;
          font-size: 12px;
          border-radius: 2px;
        }
      }
      .el-select {
        width: 152px;
      }
      .el-range-editor {
        width: 342px;
        margin-right: 50px;
        /deep/.el-range-separator {
          padding: 0 !important;
        }
      }
      .mr {
        margin-right: 20px;
      }
      .search-btn {
        .reset {
          border: 1px solid #299ffe;
          color: #299ffe;
        }

        .el-button {
          width: 56px;
          height: 26px;
          padding: 0;
          font-size: 12px;
          //
          .btn-info {
            display: flex;
            align-items: center;
            justify-content: center;
            .icon {
              font-size: 12px;
            }
          }
        }
      }
    }

    .web-list {
      padding: 0 20px;
      /deep/.el-table th {
        background: #fafbfc;
        font-size: 12px;
        color: #333;
      }
      /deep/.el-table td {
        font-size: 10px;
        color: #666;
      }
      .delete {
        color: #f26f70;
      }
    }
  }
  /deep/.el-button--text {
    margin-left: 10px;
  }
}

.skin_theme_one.web-bodyMain {
  box-shadow: 0px 5px 10px 0px rgba(0, 18, 153, 0.2);
  background-color: #ffffff;
  .my-web {
    background-color: #ffffff;

    .web-search,
    .web-list {
      background: #fff;
      color: #333;
    }
    .web-search {
      .el-input {
        /deep/.el-input__inner {
          background-color: #fff;
        }
      }
      .el-button--primary {
        background-color: #4943ff;
        border: 0;
        box-shadow: 0px 2px 8px 0px rgba(21, 2, 0, 0.69);
      }
    }
    .web-list {
      /deep/.el-table th {
        background: #fafbfc;
        color: #333;
      }
      /deep/.el-table td {
        color: #666;
      }
    }
    /deep/.number {
      background: #fafbfc !important;
      color: #333 !important;
    }
    /deep/.number.active {
      color: #fff !important;
      background-color: #4943ff !important;
    }
  }
}

.skin_theme_two.web-bodyMain {
  background: linear-gradient(
    205deg,
    #24bca8 0%,
    rgba(27, 26, 31, 0.19) 32%,
    #1b1a1f 100%
  );

  box-shadow: 4px 6px 16px 0px #000000;
  .my-web {
    background-color: #232227;
    .web-search,
    .web-list {
      background-color: #232227;
      color: #fff;
    }
    .web-search {
      .el-input {
        /deep/.el-input__inner {
          background-color: #232227;
        }
      }
      .el-button--primary {
        background: linear-gradient(
          201deg,
          #24bca8 0%,
          rgba(27, 26, 31, 0.19) 32%,
          #1b1a1f 100%
        );
        border: 0;
        box-shadow: 0px 2px 8px 0px rgba(21, 2, 0, 0.69);
      }
    }
    .web-list {
      /deep/.el-table th {
        background-color: #232227;
        color: #fff;
        border: 0;
      }
      /deep/.el-table td {
        background: #232227;
        border-right: 0;
        color: #fff;
        border-bottom: 1px solid #4d4c50;
      }
    }

    /deep/.number {
      background: #232227 !important;
      color: #fff !important;
    }
    /deep/.number.active {
      color: #fff !important;
      background-color: #ec7a6b !important;
    }
    /deep/.el-table--border,
    /deep/.el-table--group {
      border: 0;
    }
    .el-table::before,
    .el-table::after {
      height: 0;
      // background-color: #4d4c50;
    }
  }
  /deep/.el-table__body-wrapper {
    background-color: #232227;
  }
}
</style>
